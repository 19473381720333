import React, {useContext, useEffect, useState} from 'react';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {Link, useNavigate} from 'react-router-dom';
import constants, {saveUserLocally} from '../../../utils/constants';
import {fetchDataFromAPI} from '../../../utils/api';
import {DataContext} from '../../../context/dataContext';
import CommonButton from '../../../components/CommonButton/CommonButton';
import {toast} from 'react-toastify';

const ForgotPassword = () => {
  const navigate = useNavigate();

  const dataContext = useContext(DataContext);
  const {loading, setLoading} = dataContext;
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Invalid email')
        .required('Please enter your email'),
    }),

    onSubmit: (values) => {
      const {email} = values;

      const body = {
        email: email,
      };

      dataContext?.setLoading(true);
      fetchDataFromAPI('forgotpassword', 'post', body, '')
        .then((res) => {
          console.log('res', res);
          setShowSuccessMessage(true);
          formik.resetForm();
          toast.success(res?.message, {
            position: toast.POSITION.TOP_RIGHT,
            className: 'bg-success text-white',
          });
          dataContext?.setLoading(false);
        })
        .catch((error) => {
          dataContext?.setLoading(false);
          toast.error(error?.response?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
            className: 'bg-danger text-white',
          });
          console.log('error', error);
        });
      // functionlogin(values);
    },
  });
  return (
    <main>
      <div className="container">
        <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6 col-md-6 d-flex flex-column align-items-center justify-content-center">
                <div className="d-flex justify-content-center py-4">
                  <a
                    style={{textDecorationLine: 'none'}}
                    href="index.html"
                    className="logo d-flex align-items-center w-auto">
                    {/* <img src="assets/img/logo.png" alt="" /> */}
                    <span className="d-none d-lg-block">
                      Transectra - Sales Executive
                    </span>
                  </a>
                </div>
                {/* End Logo */}
                <div className="card mb-3">
                  <div className="card-body">
                    <div className="pt-4 pb-2">
                      <h5 className="card-title text-center pb-0 fs-4">
                        Forgot Password
                      </h5>
                      {/* <p className="text-center small">Enter your email</p> */}
                    </div>
                    <form
                      className="row g-3 needs-validation"
                      onSubmit={formik.handleSubmit}>
                      <div className="col-12">
                        <label htmlFor="yourPassword" className="form-label">
                          Email
                        </label>
                        <input
                          placeholder="Please enter your email"
                          type="text"
                          name="email"
                          className={`form-control ${
                            formik.touched.email && formik.errors.email
                              ? 'is-invalid'
                              : ''
                          }`}
                          id="email"
                          value={formik.values.email}
                          onChange={(e) => {
                            formik.setFieldValue(
                              'email',
                              e.target.value?.trimStart(),
                            );
                          }}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.email && formik.errors.email && (
                          <div className="invalid-feedback">
                            {formik.errors.email}
                          </div>
                        )}
                      </div>

                      <div className="text-center mt-3">
                        <CommonButton
                          loading={loading}
                          onClick={formik.handleSubmit}
                          label="Forgot Password"
                          loadingLabel="Please wait..."
                        />
                      </div>
                      {/* <div className="col-12">
                      <p className="small mb-0">
                        Don't have account?{" "}
                        <a href="pages-register.html">Create an account</a>
                      </p>
                    </div> */}
                    </form>
                    {showSuccessMessage && (
                      <div className="alert alert-success mt-3" role="alert">
                        Thank you! you will receive an email with instructions
                        on how to reset your password.
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </main>
  );
};

export default ForgotPassword;
