import React, {useContext, useState} from 'react';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {useNavigate} from 'react-router-dom';
import constants, {saveUserLocally} from '../../../utils/constants';
import {fetchDataFromAPI} from '../../../utils/api';
import {DataContext} from '../../../context/dataContext';
import CommonButton from '../../../components/CommonButton/CommonButton';
import {toast} from 'react-toastify';

const ResetPassword = () => {
  const navigate = useNavigate();
  const [showPass, setShowPass] = useState(false);
  const [showConfirmPass, setShowConfirmPass] = useState(false);
  const dataContext = useContext(DataContext);

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .matches(/^\S*$/, 'Space not valid in password.')
        .required('Please enter your password'),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('Please confirm your password'),
    }),
    onSubmit: (values) => {
      const {email, password} = values;
      dataContext?.setLoading(true);

      const body = {
        email: email,
        password: password,
      };

      fetchDataFromAPI('reset-password', 'post', body, '')
        .then((res) => {
          dataContext?.setLoading(false);
          toast.success(res?.message, {
            position: toast.POSITION.TOP_RIGHT,
            className: 'bg-success text-white',
          });
          saveUserLocally(JSON.stringify(res?.data));
          navigate('/login');
        })
        .catch((error) => {
          dataContext?.setLoading(false);
          toast.error(error?.response?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
            className: 'bg-danger text-white',
          });
          console.log('error', error);
        });
    },
  });

  return (
    <main>
      <div className="container">
        <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6 col-md-6 d-flex flex-column align-items-center justify-content-center">
                <div className="d-flex justify-content-center py-4">
                  <a
                    style={{textDecorationLine: 'none'}}
                    href="index.html"
                    className="logo d-flex align-items-center w-auto">
                    <span className="d-none d-lg-block">
                      Transectra - Sales Executive
                    </span>
                  </a>
                </div>
                <div className="card mb-3">
                  <div className="card-body">
                    <div className="pt-4 pb-2">
                      <h5 className="card-title text-center pb-0 fs-4">
                        Reset Your Password
                      </h5>
                    </div>
                    <form
                      className="row g-3 needs-validation"
                      onSubmit={formik.handleSubmit}>
                      <div className="col-12">
                        <label htmlFor="password" className="form-label">
                          New Password
                        </label>
                        <input
                          placeholder="Password"
                          type={showPass ? 'text' : 'password'}
                          name="password"
                          className={`form-control ${
                            formik.touched.password && formik.errors.password
                              ? 'is-invalid'
                              : ''
                          }`}
                          id="password"
                          value={formik.values.password}
                          onChange={(e) => {
                            formik.setFieldValue(
                              'password',
                              e.target.value?.trimStart(),
                            );
                          }}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.password && formik.errors.password && (
                          <div className="invalid-feedback">
                            {formik.errors.password}
                          </div>
                        )}
                      </div>
                      <div className="col-12">
                        <label htmlFor="confirmPassword" className="form-label">
                          Confirm Password
                        </label>
                        <input
                          placeholder="Confirm Password"
                          type={showConfirmPass ? 'text' : 'password'}
                          name="confirmPassword"
                          className={`form-control ${
                            formik.touched.confirmPassword &&
                            formik.errors.confirmPassword
                              ? 'is-invalid'
                              : ''
                          }`}
                          id="confirmPassword"
                          value={formik.values.confirmPassword}
                          onChange={(e) => {
                            formik.setFieldValue(
                              'confirmPassword',
                              e.target.value?.trimStart(),
                            );
                          }}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.confirmPassword &&
                          formik.errors.confirmPassword && (
                            <div className="invalid-feedback">
                              {formik.errors.confirmPassword}
                            </div>
                          )}
                      </div>
                      <div className="text-center mt-3">
                        <CommonButton
                          loading={dataContext?.loading}
                          onClick={formik.handleSubmit}
                          label="Reset Password"
                          loadingLabel="Please wait..."
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </main>
  );
};

export default ResetPassword;
