import React, {useEffect} from 'react';
import {Navigate, Route, Routes, useLocation} from 'react-router-dom';
import routes from './routes/routes';
import Error from './pages/Error/Error';
import Loginscreen from './pages/Auth/Loginscreen/Loginscreen';
import Registerscreen from './pages/Auth/Registerscreen/Registerscreen';

import Sidebar from './components/Sidebar/Sidebar';

// css
import './assets/css/style.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import Header from './components/Header/Header';
import ForgotPassword from './pages/Auth/ForgotPass/ForgotPassword';
import {toast, ToastContainer} from 'react-toastify';
import ResetPassword from './pages/Auth/ResetPass/ResetPassword';
import {getMessaging, onMessage} from 'firebase/messaging';
import useFcmToken from './context/useFcmToken';
import {app} from './firebase/firebase';

const App = () => {
  const {pathname} = useLocation();

  const {fcmToken, notificationPermissionStatus} = useFcmToken();
  // Use the token as needed
  fcmToken && console.log('FCM token:', fcmToken);

  // eslint-disable-next-line react-hooks/rules-of-hooks

  useEffect(() => {
    if (typeof window !== 'undefined' && 'serviceWorker' in navigator) {
      const messaging = getMessaging(app);
      const unsubscribe = onMessage(messaging, (payload) => {
        console.log('Foreground push notification received:', payload);
        const title = payload?.notification?.title;
        const body = payload?.notification?.body;

        toast.success(
          <div>
            {title}
            <br />
            {body}
          </div>,
          {
            position: 'top-right',
            style: {width: '300px', height: '100px'},
          },
        );
        // Handle the received push notification while the app is in the foreground
        // You can display a notification or update the UI based on the payload
      });
      return () => {
        unsubscribe(); // Unsubscribe from the onMessage event
      };
    }
  }, []);
  return (
    <>
      <ToastContainer />

      {pathname !== '/login' &&
        pathname !== '/forgot-password' &&
        pathname !== '/reset-password' &&
        pathname !== '/404' && <Header />}
      {pathname !== '/login' &&
        pathname !== '/forgot-password' &&
        pathname !== '/reset-password' &&
        pathname !== '/404' && <Sidebar />}
      <Routes>
        <Route path="/login" element={<Loginscreen />} />
        {/* <Route path="/register" element={<Registerscreen />} /> */}
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password-sales" element={<ResetPassword />} />

        {routes.map(({path, element, title}) => (
          <Route key={title} path={path} element={element} />
        ))}
        <Route path="/404" element={<Error />} />
        <Route path="*" element={<Navigate to="/404" replace />} />
        <Route path="/" element={<Navigate to="/dashboard" replace />} />
      </Routes>
    </>
  );
};

export default App;
