import React, {useContext, useEffect, useState} from 'react';
import {DataContext} from '../../../context/dataContext';
import {useParams} from 'react-router-dom';

import {fetchDataFromAPI} from '../../../utils/api';

import {getUser} from '../../../utils/constants';
import Processing from '../../../components/Processing/Processing';
import {fetchDataFromAPIIMG} from '../../../utils/image';

const ViewBuyRequest = () => {
  const dataContext = useContext(DataContext);

  const {id} = useParams();

  const [diamondData, setDiamondData] = useState({});

  const user = JSON.parse(getUser());

  useEffect(() => {
    getDiamond();
  }, []);

  const getDiamond = () => {
    dataContext?.setLoading(true);
    fetchDataFromAPIIMG(`buyerRequest?id=${id}`, 'get', '', user?.auth_token)
      .then((res) => {
        console.log('res id', res?.data?.results);
        setDiamondData(res?.data?.results[0]);
        dataContext?.setLoading(false);
      })
      .catch((error) => {
        dataContext?.setLoading(false);
        console.log('error -->', error);
      });
  };

  const isImage = (url) => {
    return /\.(jpg|jpeg|png|gif)$/i.test(url);
  };

  const isVideo = (url) => {
    return /\.(mp4|webm|ogg)$/i.test(url);
  };

  const isPDF = (url) => {
    return /\.pdf$/i.test(url);
  };

  const formatValue = (val) => {
    if (Array.isArray(val)) {
      if (val.length > 0 && typeof val[0] === 'object') {
        return (
          <table className="table table-sm table-bordered">
            <tbody>
              {val.map((item, idx) => (
                <tr key={idx}>
                  <td>{formatValue(item)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        );
      }
      return val.join(', ');
    } else if (typeof val === 'object' && val !== null) {
      if (val.hasOwnProperty('from') && val.hasOwnProperty('to')) {
        return `${val.from} to ${val.to}`;
      }
      return (
        <table className="table table-sm table-bordered">
          <tbody>
            {Object.keys(val)
              .filter(
                (subKey) => !['_id', 'createdAt', 'updatedAt'].includes(subKey),
              )
              .map((subKey, idx) => (
                <tr key={idx}>
                  <td>
                    <strong>{subKey}</strong>
                  </td>
                  <td>{formatValue(val[subKey])}</td>
                </tr>
              ))}
          </tbody>
        </table>
      );
    } else if (
      typeof val === 'string' &&
      (isImage(val) || isVideo(val) || isPDF(val))
    ) {
      return (
        <a href={val} target="_blank" rel="noopener noreferrer">
          {isImage(val) && (
            <img
              src={val}
              alt="file"
              style={{maxWidth: '100px', maxHeight: '100px'}}
            />
          )}
          {isVideo(val) && (
            <video
              src={val}
              style={{maxWidth: '100px', maxHeight: '100px'}}
              controls
            />
          )}
          {isPDF(val) && <span>PDF</span>}
        </a>
      );
    } else {
      return val;
    }
  };

  const DisplayDataItem = ({label, value}) => (
    <tr>
      <td style={{textAlign: 'center'}}>
        <strong>{label}</strong>
      </td>
      <td style={{textAlign: 'center'}}>{formatValue(value)}</td>
    </tr>
  );

  const diamondDetails = diamondData.diamondData || {};

  return (
    <main id="main" className="main">
      {dataContext.loading && <Processing />}
      <h2>Diamond Details</h2>
      <table className="table table-striped table-bordered table-hover">
        <tbody>
          {Object.keys(diamondData)
            .filter((key) => !['_id', 'createdAt', 'updatedAt'].includes(key))
            .map((key, index) => (
              <DisplayDataItem
                key={index}
                label={key}
                value={diamondData[key]}
              />
            ))}
          {/* {Object.keys(diamondDetails).map((key, index) => (
            <DisplayDataItem
              key={index}
              label={key}
              value={diamondDetails[key]}
            />
          ))} */}
        </tbody>
      </table>
    </main>
  );
};

export default ViewBuyRequest;
