import React, {useState, useEffect, useContext} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {useFormik} from 'formik';
import * as Yup from 'yup';

import {toast} from 'react-toastify';
import Processing from '../../../components/Processing/Processing';
import {DataContext} from '../../../context/dataContext';
import {getUser, saveUserLocally} from '../../../utils/constants';
import CommonButton from '../../../components/CommonButton/CommonButton';
import {fetchDataFromAPIIMG} from '../../../utils/image';
import {fetchDataFromAPI} from '../../../utils/api';
import {FormGroup} from 'react-bootstrap';

const EditAdminProfile = () => {
  const user = JSON.parse(getUser());

  const dataContext = useContext(DataContext);
  const [url, setURL] = useState(null);
  const [showNewPass, setShowNewPass] = useState(false);
  const [showConNewPass, setShowConNewPass] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    getUserData();
  }, []);

  const getUserData = () => {
    dataContext?.setLoading(true);
    formik.setFieldValue('firstname', user?.first_name);
    formik.setFieldValue('lastname', user?.last_name);
    formik.setFieldValue('email', user?.email);
    formik.setFieldValue('phone', user?.phone);
    formik.setFieldValue('profile', user?.profile_image);
    formik.setFieldValue('gmail_id', user?.gmail_id);
    formik.setFieldValue('whatsapp_id', user?.whatsapp_id);
    formik.setFieldValue('skype_id', user?.skype_id);
    dataContext?.setLoading(false);
  };

  const formik = useFormik({
    initialValues: {
      firstname: '',
      lastname: '',
      email: '',
      newPass: '',
      conNewPass: '',
      profile: '',
      gmail_id: '',
      whatsapp_id: '',
      skype_id: '',
    },

    validationSchema: Yup.object({
      firstname: Yup.string().required('First Name is required'),
      lastname: Yup.string().required('Last Name is required'),
      email: Yup.string().email('Invalid email').required('Email is required'),
      role: Yup.string().notRequired(),
      newPass: Yup.string()
        // Scenario 1: No validation restrictions
        // .notRequired()

        // Scenario 2: Relaxed validation criteria
        .min(8, 'Password must be at least 8 characters long.')
        .notRequired(),

      conNewPass: Yup.string()
        .oneOf([Yup.ref('newPass'), null], 'Passwords must match.')
        .notRequired(),
      profile: Yup.mixed().notRequired(),
      gmail_id: Yup.string().notRequired(),
      whatsapp_id: Yup.string().notRequired(),
      skype_id: Yup.string().notRequired(),
    }),

    onSubmit: (values) => {
      const {
        firstname,
        lastname,
        email,
        conNewPass,
        phone,
        newPass,
        profile,
        gmail_id,
        whatsapp_id,
        skype_id,
      } = values;

      try {
        if (newPass && !conNewPass) {
          formik.setFieldError(
            'conNewPass',
            'Please enter repeat new password',
          );
          return;
        }
        dataContext?.setLoading(true);
        // const body = {
        //   firstName: firstName,
        //   lastName: lastName,
        //   email: email,
        // };

        const data = {
          first_name: firstname,
          last_name: lastname,
          phone: phone,
          email: email,
          password: conNewPass ? conNewPass : user?.password,
          profile_image: url ? url : profile,
          gmail_id: gmail_id,
          whatsapp_id: whatsapp_id,
          skype_id: skype_id,
        };

        console.log('data', data);

        fetchDataFromAPI(
          `editprofile?sales_executive_id=${user?._id}`,
          'post',
          data,
          user?.auth_token,
        )
          .then((response) => {
            toast.success('Profile updated successfully ✅', {
              position: toast.POSITION.TOP_RIGHT,
              className: 'bg-success text-white',
            });

            console.log('response', response);

            saveUserLocally(JSON.stringify(response?.data));
            dataContext?.setLoading(false);
            navigate('/');
          })
          .catch((error) => {
            toast.error(error?.response?.data?.message, {
              position: toast.POSITION.TOP_RIGHT,
              className: 'bg-danger text-white',
            });
            dataContext?.setLoading(false);

            console.log('error', error);
          });
      } catch (error) {
        console.log('catch error: ', error);

        dataContext?.setLoading(false);
      }
    },
  });

  const handleFileChangePdf = (e) => {
    formik.setFieldValue('profile', e.target.files[0]);
    // const pdfFile = e?.target?.files[0];
    const data = new FormData();
    data.append('image', e?.target?.files[0]);
    // Reset value for the pdfFile field
    // Clear any validation error for the pdfFile field
    dataContext.setLoading(true);
    fetchDataFromAPIIMG('upload', 'post', data, '')
      .then((res) => {
        console.log('res: ', res?.data);

        setURL(res?.data);
        dataContext.setLoading(false);
      })
      .catch((error) => {
        console.log('error: ', error);
        dataContext.setLoading(false);
      });
  };

  return (
    <main id="main" className="main">
      {dataContext.loading && <Processing />}
      <div className="pagetitle">
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Dashboard</Link>
            </li>
            <li className="breadcrumb-item active">
              <span>Edit Profile</span>
            </li>
          </ol>
        </nav>
      </div>
      {/* End Page Title */}
      <section className="section profile">
        <div className="row">
          <div className="card">
            <div className="card-body pt-3">
              {/* Bordered Tabs */}

              <div className="tab-content">
                <div className="pt-3" id="profile-edit">
                  {/* Profile Edit Form */}
                  <form
                    className="needs-validation"
                    onSubmit={formik.handleSubmit}>
                    <div className="row mb-3">
                      <label
                        htmlFor="firstName"
                        className="col-md-4 col-lg-3 col-form-label">
                        First Name
                      </label>
                      <div className="col-md-8 col-lg-9">
                        <input
                          name="firstName"
                          type="text"
                          className={`form-control ${
                            formik.touched.firstname && formik.errors.firstname
                              ? 'is-invalid'
                              : ''
                          }`}
                          id="firstName"
                          value={formik.values.firstname}
                          onChange={(e) => {
                            formik.setFieldValue(
                              'firstname',
                              e.target.value?.trimStart(),
                            );
                          }}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.firstname &&
                          formik.errors.firstname && (
                            <div className="invalid-feedback">
                              {formik.errors.firstname}
                            </div>
                          )}
                      </div>
                    </div>

                    <div className="row mb-3">
                      <label
                        htmlFor="lastName"
                        className="col-md-4 col-lg-3 col-form-label">
                        Last Name
                      </label>
                      <div className="col-md-8 col-lg-9">
                        <input
                          name="lastName"
                          type="text"
                          className={`form-control ${
                            formik.touched.lastname && formik.errors.lastname
                              ? 'is-invalid'
                              : ''
                          }`}
                          id="lastName"
                          value={formik.values.lastname}
                          // onChange={formik.handleChange}
                          onChange={(e) => {
                            formik.setFieldValue(
                              'lastname',
                              e.target.value?.trimStart(),
                            );
                          }}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.lastname && formik.errors.lastname && (
                          <div className="invalid-feedback">
                            {formik.errors.lastname}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="row mb-3">
                      <label
                        htmlFor="email"
                        className="col-md-4 col-lg-3 col-form-label">
                        Email
                      </label>
                      <div className="col-md-8 col-lg-9">
                        <input
                          name="email"
                          type="text"
                          className={`form-control ${
                            formik.touched.email && formik.errors.email
                              ? 'is-invalid'
                              : ''
                          }`}
                          value={formik.values.email}
                          id="email"
                          onChange={(e) => {
                            formik.setFieldValue(
                              'email',
                              e.target.value?.trimStart(),
                            );
                          }}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.email && formik.errors.email && (
                          <div className="invalid-feedback">
                            {formik.errors.email}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="row mb-3">
                      <label
                        htmlFor="phone"
                        className="col-md-4 col-lg-3 col-form-label">
                        Phone
                      </label>
                      <div className="col-md-8 col-lg-9">
                        <input
                          name="phone"
                          type="text"
                          className={`form-control ${
                            formik.touched.phone && formik.errors.phone
                              ? 'is-invalid'
                              : ''
                          }`}
                          value={formik.values.phone}
                          id="phone"
                          onChange={(e) => {
                            const value = e.target.value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
                            formik.setFieldValue('phone', value.trimStart());
                          }}
                          maxLength={10}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.phone && formik.errors.phone && (
                          <div className="invalid-feedback">
                            {formik.errors.phone}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="row mb-3">
                      <label
                        htmlFor="phone"
                        className="col-md-4 col-lg-3 col-form-label">
                        Gmail Url
                      </label>
                      <div className="col-md-8 col-lg-9">
                        <input
                          name="phone"
                          type="text"
                          className={`form-control ${
                            formik.touched.gmail_id && formik.errors.gmail_id
                              ? 'is-invalid'
                              : ''
                          }`}
                          value={formik.values.gmail_id}
                          id="gmail_id"
                          onChange={(e) => {
                            // const value = e.target.value.replace(/[^0-9]/g, '');
                            formik.setFieldValue(
                              'gmail_id',
                              e.target.value.trimStart(),
                            );
                          }}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.gmail_id && formik.errors.gmail_id && (
                          <div className="invalid-feedback">
                            {formik.errors.gmail_id}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="row mb-3">
                      <label
                        htmlFor="phone"
                        className="col-md-4 col-lg-3 col-form-label">
                        Whatsapp Number
                      </label>
                      <div className="col-md-8 col-lg-9">
                        <input
                          name="whatsapp_id"
                          type="text"
                          className={`form-control ${
                            formik.touched.whatsapp_id &&
                            formik.errors.whatsapp_id
                              ? 'is-invalid'
                              : ''
                          }`}
                          value={formik.values.whatsapp_id}
                          maxLength={10}
                          id="whatsapp_id"
                          onChange={(e) => {
                            const value = e.target.value.replace(/[^0-9]/g, '');
                            formik.setFieldValue(
                              'whatsapp_id',
                              value.trimStart(),
                            );
                          }}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.whatsapp_id &&
                          formik.errors.whatsapp_id && (
                            <div className="invalid-feedback">
                              {formik.errors.whatsapp_id}
                            </div>
                          )}
                      </div>
                    </div>
                    <div className="row mb-3">
                      <label
                        htmlFor="phone"
                        className="col-md-4 col-lg-3 col-form-label">
                        Skype Url
                      </label>
                      <div className="col-md-8 col-lg-9">
                        <input
                          name="phone"
                          type="text"
                          className={`form-control ${
                            formik.touched.skype_id && formik.errors.skype_id
                              ? 'is-invalid'
                              : ''
                          }`}
                          value={formik.values.skype_id}
                          id="skype_id"
                          onChange={(e) => {
                            // const value = e.target.value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
                            formik.setFieldValue(
                              'skype_id',
                              e.target.value.trimStart(),
                            );
                          }}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.skype_id && formik.errors.skype_id && (
                          <div className="invalid-feedback">
                            {formik.errors.skype_id}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-md-12">
                        <label htmlFor="pdfFile" className="form-label">
                          Choose Profile Image
                        </label>
                        <FormGroup>
                          <input
                            className="form-control"
                            type="file"
                            name="pdfFile"
                            accept="image/*"
                            id="pdfFile"
                            onChange={handleFileChangePdf}
                          />
                          {formik.touched.profile &&
                            formik.errors.profile &&
                            !url && (
                              <div className="text-danger">
                                {formik.errors.profile}
                              </div>
                            )}
                        </FormGroup>
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-md-6">
                        <label htmlFor="firstName" className="form-label">
                          New Password
                        </label>
                        <div className="d-flex">
                          <input
                            name="newPass"
                            type={showNewPass ? 'text' : 'password'}
                            className="form-control"
                            style={{
                              borderTopRightRadius: '0',
                              borderBottomRightRadius: '0',
                            }}
                            id="newPass"
                            onChange={(e) => {
                              formik.setFieldValue(
                                'newPass',
                                e.target.value?.trimStart(),
                              );
                            }}
                          />
                          <button
                            type="button"
                            className="btn btn-outline-secondary"
                            style={{
                              borderTopLeftRadius: '0', // Adjust the radius value
                              borderBottomLeftRadius: '0', // Adjust the radius value
                            }}
                            onClick={() => setShowNewPass(!showNewPass)}>
                            {showNewPass ? (
                              <i className="bi bi-eye-fill"></i>
                            ) : (
                              <i className="bi bi-eye-slash-fill"></i>
                            )}
                          </button>
                        </div>
                        {formik.touched.newPass && formik.errors.newPass && (
                          <div className="text-danger">
                            {formik.errors.newPass}
                          </div>
                        )}
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="lastName" className="form-label">
                          Confirm Password
                        </label>
                        <div className="d-flex">
                          <input
                            name="conNewPass"
                            type={showConNewPass ? 'text' : 'password'}
                            className="form-control"
                            style={{
                              borderTopRightRadius: '0',
                              borderBottomRightRadius: '0',
                            }}
                            id="conNewPass"
                            onChange={(e) => {
                              formik.setFieldValue(
                                'conNewPass',
                                e.target.value?.trimStart(),
                              );
                            }}
                          />
                          <button
                            type="button"
                            className="btn btn-outline-secondary"
                            style={{
                              borderTopLeftRadius: '0', // Adjust the radius value
                              borderBottomLeftRadius: '0', // Adjust the radius value
                            }}
                            onClick={() => setShowConNewPass(!showConNewPass)}>
                            {showConNewPass ? (
                              <i className="bi bi-eye-fill"></i>
                            ) : (
                              <i className="bi bi-eye-slash-fill"></i>
                            )}
                          </button>
                        </div>
                        {formik.touched.conNewPass &&
                          formik.errors.conNewPass && (
                            <div className="text-danger">
                              {formik.errors.conNewPass}
                            </div>
                          )}
                      </div>
                    </div>

                    <div className="text-center mt-3">
                      <CommonButton
                        loading={dataContext.loading}
                        onClick={formik.handleSubmit}
                        label="Edit"
                        loadingLabel="Please wait..."
                      />
                    </div>
                  </form>
                  {/* End Profile Edit Form */}
                </div>
              </div>
              {/* End Bordered Tabs */}
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default EditAdminProfile;
